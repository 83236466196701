<template>
  <v-dialog
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    v-model="dialog"
  >
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="deal_summary_data.status == 'active'"
        color="green darken-4"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Make a new offer
      </v-btn>
    </template> -->

    <v-card>
      <v-toolbar class="orange darken-3">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title>
          {{ edit_summary_offer_dialog ? "Update " : "Make an " }} offer
        </v-toolbar-title>

        <v-spacer />
        <v-toolbar-items>
          <!-- <v-btn dark text @click="dialog = false"> Submit </v-btn> -->
        </v-toolbar-items>
      </v-toolbar>

      <v-container>
        <br />

        <p>
          Thank you for your interest in this lending opportunity. If your offer
          is short listed, you will be provided with the full application
          details for further evaluation.
        </p>

        <p>
          An offer made here is not binding; though, given the information
          provided in the summary will match application, your offer here should
          be true to your intentions on this file. An official mortgage
          commitment with conditions for borrower to meet must still be issued
          with your authorization and signature.
        </p>

        <p>
          <strong
            >You are able to make multiple offers on a lending opportunity,
            should you want to change your combination of mortgage
            amount/term/rates/conditions etc.
          </strong>
        </p>

        <br />

        <v-row class="d-flex flex-column flex-sm-row">
          <v-col cols="12" md="3">
            <p class="mt-2 mb-0">You are making an offer on behalf of :</p>
          </v-col>

          <v-col cols="12" md="4" v-if="associated_companies.length === 1" class="d-flex align-end">
            {{ associated_companies[0].name }}
          </v-col>

          <v-col cols="12" md="4" v-if="associated_companies.length > 1">
            <v-select
              v-model="new_offer.selected_company"
              :items="associated_companies"
              item-text="name"
              item-value="id"
              label="Select Company"
              @change="
                handle_change_company_selection();
                new_offer.selected_account = null;
              "
              hide-details="auto"
              dense
            />
          </v-col>
        </v-row>

        <!-- this is to show select company accounts field if there are no other company associated-->
        <v-row
          class="mt-5 d-flex flex-column flex-sm-row"
          v-if="
            associated_companies.some((c) => {
              if (c.id == new_offer.selected_company)
                return c.accounts.length > 0;
            })
          "
        >
          <v-col cols="12" md="2">
            <p class="mt-2 mb-0">Select an account :</p>
          </v-col>
          <v-col cols="12" md="5">
            <v-select
              v-model="new_offer.selected_account"
              :items="accounts_options"
              item-text="name"
              item-value="id"
              label="click to select account"
              hide-details="auto"
              :rules="formRules.required"
              dense
            />
          </v-col>
        </v-row>

        <v-divider class="mt-10" />

        <div class="my-5 d-flex justify-end">
          <v-btn @click="deal_summary_dialog = true" text color="teal">
            <v-icon class="text-body-1 mr-2">mdi-magnify</v-icon> view deal
            information
          </v-btn>
        </div>

        <v-form ref="lender_deal_offer_form" v-model="formRules.valid">
          <v-text-field
            outlined
            label="Mortgage Amount ($)"
            v-model="new_offer.mortgage_amount"
            :placeholder="deal_summary_data.amount_requested"
            :rules="formRules.required"
            @blur="
              new_offer.mortgage_amount = $options.filters.formatPrice(
                new_offer.mortgage_amount
              )
            "
          />

          <p>Mortgage Position</p>

          <v-radio-group
            row
            v-model="new_offer.mortgage_position"
            :rules="formRules.required"
          >
            <v-radio label="1st" value="1" />

            <v-radio label="2nd" value="2" />

            <v-radio label="3rd" value="3" />

            <v-radio label="4th" value="4" />
          </v-radio-group>

          <v-text-field
            outlined
            label="Term (months)"
            placeholder="Term (months)"
            type="number"
            v-model="new_offer.mortgage_term"
            :rules="formRules.required"
          />

          <v-select
            outlined
            label="Term Type"
            placeholder="Term Type"
            v-model="new_offer.term_type"
            :items="['Closed', 'Open - Full', 'Open - After Months']"
            :rules="formRules.required"
            @change="new_offer.number_of_months = null"
          />

          <v-text-field
            v-if="new_offer.term_type == 'Open - After Months'"
            outlined
            label="Number of Months to Open After"
            placeholder="Number of Months"
            type="number"
            v-model="new_offer.number_of_months"
            :rules="formRules.number_required"
          />

          <v-text-field
            outlined
            label="Interest Rate %"
            placeholder="Interest Rate %"
            type="number"
            v-model="new_offer.interest_rate"
            :rules="formRules.required"
          />

          <v-text-field
            outlined
            label="Lender Fee %"
            placeholder="Lender Fee %"
            type="number"
            v-model="new_offer.lender_fee"
            :rules="formRules.required"
          />

          <v-textarea
            outlined
            label="Comments"
            placeholder="Comments"
            v-model="new_offer.comments"
          />

          <p>
            You can choose to disable conditions. The less conditions in the
            offer, the more competitive it is to the borrower when choosing
            between options.
          </p>

          <v-switch
            label="Appraisal is required on this property"
            v-model="new_offer.appraisal"
          />

          <v-switch
            label="Insurance with lender named loss payable required on this property"
            v-model="new_offer.insurance"
          />

          <v-switch
            label="Credit check is required on borrower (Note, we can only send credit reports with applicaitons through Filogix)"
            v-model="new_offer.credit_check"
          />

          <v-switch
            label="Review of income documents required by lender"
            v-model="new_offer.income_verification"
          />

          <v-switch
            label="Review of personal income tax documents required by lender"
            v-model="new_offer.tax_verification"
          />

          <v-switch
            label="Review of strata/estoppel/condo documents required by lender"
            v-model="new_offer.strata"
          />

          <div class="mb-5">
            <v-row
              v-for="(addl_condition, index) in new_offer.addl_conditions"
              :key="index"
            >
              <v-col>
                <div class="d-flex align-start">
                  <v-textarea
                    v-model="addl_condition.condition"
                    label="Click here to input a condition"
                    rows="1"
                    outlined
                  />

                  <v-btn
                    class="mt-2"
                    @click="new_offer.addl_conditions.splice(index, 1)"
                    icon
                    color="red"
                    title="Remove Condition"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>

            <p class="grey--text">
              Multiple custom conditions can be added by clicking "add
              condition" below:
            </p>

            <v-btn @click="handle_add_addl_condition"> + Add Condition </v-btn>
          </div>

          <v-alert
            v-show="errorMsg !== null"
            type="error"
            title="Error"
            transition="fade-transition"
          >
            {{ errorMsg }}
          </v-alert>

          <v-btn
            color="green darken-4"
            v-if="!form_processing && !edit_summary_offer_dialog"
            @click="submit_offer"
          >
            Submit Offer
          </v-btn>

          <v-btn
            color="green darken-4"
            v-if="!form_processing && edit_summary_offer_dialog"
            @click="update_offer"
          >
            Update Offer
          </v-btn>

          &nbsp;

          <v-btn
            color="red"
            v-if="!offer_submit_success"
            :disabled="form_processing"
            @click="$emit('close')"
          >
            Cancel/Close
          </v-btn>

          <v-progress-circular
            class="ml-5"
            color="primary"
            v-if="!offer_submit_success && form_processing"
            indeterminate
          />

          <v-chip
            class="ma-2"
            color="green"
            text-color="white"
            v-if="offer_submit_success && errorMsg == null"
          >
            Offer recieved successfully!
          </v-chip>
        </v-form>
      </v-container>

      <v-dialog v-model="deal_summary_dialog" max-width="800">
        <v-card>
          <v-card-title class="mb-2 d-flex justify-space-between">
            <span>Deal Summary</span>
            <v-btn
              icon
              title="close"
              color="red"
              @click="deal_summary_dialog = false"
            >
              <v-icon color="red">mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-simple-table class="bordered" dense>
              <thead></thead>

              <tbody>
                <tr>
                  <td style="width: 250px">City</td>
                  <td>
                    {{ deal_summary_data.city }},
                    {{ deal_summary_data.province }}
                  </td>
                </tr>

                <tr>
                  <td style="width: 250px">Location Notes</td>
                  <td>{{ deal_summary_data.location_notes }}</td>
                </tr>

                <tr>
                  <td style="width: 250px">Property Type</td>
                  <td>{{ deal_summary_data.property_type }}</td>
                </tr>

                <tr>
                  <td style="width: 250px">Property Value</td>
                  <td>
                    {{ deal_summary_data.property_value | formatPrice }}
                  </td>
                </tr>

                <tr>
                  <td style="width: 250px">Property Value Notes</td>
                  <td>{{ deal_summary_data.property_value_notes }}</td>
                </tr>
              </tbody>
            </v-simple-table>

            <br />

            <v-simple-table class="bordered" dense>
              <tbody>
                <tr>
                  <td style="width: 250px">Owing Against Property</td>
                  <td>
                    {{ deal_summary_data.owing_against | formatPrice }}
                  </td>
                </tr>

                <tr>
                  <td style="width: 250px">Owing Against Property Notes</td>
                  <td>{{ deal_summary_data.owing_against_notes }}</td>
                </tr>
              </tbody>
            </v-simple-table>

            <br />

            <v-simple-table class="bordered" dense>
              <tbody>
                <tr>
                  <td style="width: 250px">Amount Requested</td>
                  <td>
                    {{ deal_summary_data.amount_requested | formatPrice }}
                  </td>
                </tr>

                <tr>
                  <td style="width: 250px">Amount Requested Notes</td>
                  <td>
                    {{ deal_summary_data.amount_requested_notes }}
                  </td>
                </tr>

                <tr>
                  <td style="width: 250px">Loan To Value</td>
                  <td>{{ deal_summary_data.loan_to_value }}%</td>
                </tr>

                <tr>
                  <td style="width: 250px">Mortgage Position</td>
                  <td>
                    {{ deal_summary_data.mortgage_position | mortgagePosition }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <br />

            <v-simple-table class="bordered" dense>
              <tbody>
                <tr>
                  <td style="width: 250px">Income Notes</td>
                  <td>{{ deal_summary_data.income_notes }}</td>
                </tr>

                <tr>
                  <td style="width: 250px">Liability Notes</td>
                  <td>{{ deal_summary_data.liability_notes }}</td>
                </tr>

                <tr>
                  <td style="width: 250px">Credit Notes</td>
                  <td>{{ deal_summary_data.credit_notes }}</td>
                </tr>

                <tr>
                  <td style="width: 250px">Exit Strategy</td>
                  <td>{{ deal_summary_data.exit_strategy }}</td>
                </tr>

                <tr>
                  <td style="width: 250px">Summary Notes</td>
                  <td>{{ deal_summary_data.summary_notes }}</td>
                </tr>

                <tr>
                  <td style="width: 250px">GDS</td>
                  <td>{{ deal_summary_data.GDS }}%</td>
                </tr>

                <tr>
                  <td style="width: 250px">TDS</td>
                  <td>{{ deal_summary_data.TDS }}%</td>
                </tr>
              </tbody>
            </v-simple-table>

            <br />

            <v-simple-table class="bordered" dense>
              <tbody>
                <tr>
                  <td style="width: 250px">Suggested Mortgage Position</td>
                  <td>{{ deal_summary_data.mortgage_position }}</td>
                </tr>

                <tr>
                  <td style="width: 250px">Suggested Term</td>
                  <td>{{ deal_summary_data.proposed_term }} Months</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import API from "@/plugins/API";
import filters from "@/plugins/filters";

export default {
  props: [
    "deal_summary_data",
    "company",
    "view_type",
    "summary_link_token",
    "dialog",
    "edit_summary_offer_data",
    "edit_summary_offer_dialog",
    "from_my_bids",
  ],

  data() {
    return {
      // dialog: false,

      errorMsg: null,
      offer_submit_success: false,
      form_processing: false,
      deal_summary_dialog: false,

      formRules: {
        valid: false,
        required: [(value) => !!value || "This is a required field."],

        number_required: [
          (v) => !!v || "Required.",

          (v) =>
            v === "" ||
            v === null ||
            /^\d+$/.test(v) ||
            "Input must be a number",
        ],
      },

      new_offer: {
        mortgage_amount: null,
        mortgage_position: null,
        mortgage_term: null,
        term_type: null,
        number_of_months: null,
        interest_rate: null,
        lender_fee: null,
        comments: null,
        appraisal: true,
        insurance: true,
        credit_check: false,
        income_verification: false,
        tax_verification: false,
        strata: false,
        lender_decline: false, // upon adding offer lender_decline automatically set to false
        selected_company: null,
        selected_account: null,
        addl_conditions: [],
      },

      user_data: null,
    };
  },
  methods: {
    async update_offer() {
      this.form_processing = true;
      this.$refs.lender_deal_offer_form.validate();

      // console.log("new_offer: ", this.new_offer);

      // convert the mortgage_amount to number
      this.new_offer.mortgage_amount = String(
        this.new_offer.mortgage_amount
      ).replace(/[$,]/g, "");

      if (this.formRules.valid) {
        // If it is a logged in user, should state which user
        // If it is a token, should be the user token information
        try {
          let offer_response;

          if (this.view_type == "logged_in") {
            offer_response = await API().patch(
              "/api/user_update_summary_offer",
              {
                offer: this.new_offer,
              }
            );
          }

          if (this.view_type == "summary_link_token") {
            offer_response = await API().patch(
              `/api/summary_link_update_summary_offer?summary_link_token=${this.summary_link_token}`,
              {
                company_id: parseInt(this.new_offer.selected_company),
                offer: this.new_offer,
              }
            );
          }

          if (offer_response.status == 201) {
            // close the dialog
            this.offer_submit_success = true;
            if (!this.from_my_bids) this.$emit("getDealInfo");

            setTimeout(() => {
              this.offer_submit_success = false;
              this.$emit("close");
              // reset new offer form

              this.new_offer.mortgage_amount =
                this.deal_summary_data.amount_requested;

              this.new_offer.mortgage_term =
                this.deal_summary_data.proposed_term;

              this.new_offer.mortgage_position = String(
                this.deal_summary_data.mortgage_position
              );

              this.new_offer.interest_rate = null;
              this.new_offer.lender_fee = null;
              this.new_offer.comments = null;
              this.new_offer.appraisal = true;
              this.new_offer.insurance = true;
              this.new_offer.credit_check = false;
              this.new_offer.income_verification = false;
              this.new_offer.tax_verification = false;

              this.$refs.lender_deal_offer_form.resetValidation();

              this.form_processing = false;
            }, 1000);
          }
        } catch (error) {
          this.errorMsg = error;
          console.log(error);
          setTimeout(() => {
            this.errorMsg = null;
            this.form_processing = false;
          }, 2000);
        }
      } else {
        this.form_processing = false;
      }
    },

    async submit_offer() {
      this.form_processing = true;
      this.$refs.lender_deal_offer_form.validate();

      // convert the mortgage_amount to number
      this.new_offer.mortgage_amount = String(
        this.new_offer.mortgage_amount
      ).replace(/[$,]/g, "");

      if (this.formRules.valid) {
        // If it is a logged in user, should state which user
        // If it is a token, should be the user token information
        try {
          let offer_response;

          if (this.view_type == "logged_in") {
            // let company = this.$store.getters["Auth/getAuthUser"].companies[0].id;
            offer_response = await API().post(
              "/api/create_deal_summary_offer",
              {
                deal_summary_id: parseInt(this.deal_summary_data.id),
                company_id: parseInt(this.company.id),
                offer: this.new_offer,
              }
            );
          }

          if (this.view_type == "summary_link_token") {
            // let company =
            //   this.$store.getters["Auth/getAuthUser"].companies[0].id;
            offer_response = await API().post(
              `/api/summary_link_create_deal_summary_offer?summary_link_token=${this.summary_link_token}`,
              {
                deal_summary_id: parseInt(this.deal_summary_data.id),
                offer: this.new_offer,
              }
            );
          }

          //   console.log(offer_response);

          if (offer_response.status == 201) {
            // close the dialog
            this.offer_submit_success = true;
            if (!this.from_my_bids) this.$emit("getDealInfo");
            // this.getDealInfo();

            setTimeout(() => {
              this.offer_submit_success = false;
              this.$emit("close");
              // reset new offer form

              this.new_offer.mortgage_amount =
                this.deal_summary_data.amount_requested;

              this.new_offer.mortgage_term =
                this.deal_summary_data.proposed_term;

              this.new_offer.mortgage_position = String(
                this.deal_summary_data.mortgage_position
              );

              this.new_offer.interest_rate = null;
              this.new_offer.lender_fee = null;
              this.new_offer.comments = null;
              this.new_offer.appraisal = true;
              this.new_offer.insurance = true;
              this.new_offer.credit_check = false;
              this.new_offer.income_verification = false;
              this.new_offer.tax_verification = false;

              this.$refs.lender_deal_offer_form.resetValidation();

              this.form_processing = false;
            }, 1000);
          }
        } catch (error) {
          this.errorMsg = error;
          console.log(error);
          setTimeout(() => {
            this.errorMsg = null;
            this.form_processing = false;
          }, 2000);
        }
      } else {
        this.form_processing = false;
      }
    },

    handle_change_company_selection() {
      this.$set(
        this.new_offer,
        "selected_company",
        this.new_offer.selected_company
      );
    },

    async get_user_data() {
      try {
        let user = await API().get("/api/user-info");

        this.user_data = user.data;

        this.new_offer.selected_company = this.edit_summary_offer_dialog
          ? this.edit_summary_offer_data.company.id
          : this.user_data.companies[0].id;

        if (this.edit_summary_offer_dialog) {
          if (this.edit_summary_offer_data.account) {
            this.new_offer.selected_account =
              this.edit_summary_offer_data.account.id;
          } else {
            this.new_offer.selected_account = null;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    initialize_data() {
      this.new_offer.mortgage_term = this.deal_summary_data.proposed_term;

      this.new_offer.mortgage_position = String(
        this.deal_summary_data.mortgage_position
      );

      this.new_offer.mortgage_amount = this.$options.filters.formatPrice(
        String(this.deal_summary_data.amount_requested)
      );

      if (this.edit_summary_offer_dialog) {
        this.new_offer = this.edit_summary_offer_data;

        this.new_offer.mortgage_position = String(
          this.edit_summary_offer_data.mortgage_position
        );
      } else {
        delete this.new_offer.id;
        this.new_offer.interest_rate = null;
        this.new_offer.lender_fee = null;
        this.new_offer.comments = null;
        this.new_offer.appraisal = true;
        this.new_offer.insurance = true;
        this.new_offer.credit_check = false;
        this.new_offer.income_verification = false;
        this.new_offer.tax_verification = false;
        this.new_offer.strata = false;
      }

      this.get_user_data();
    },

    handle_add_addl_condition() {
      this.new_offer.addl_conditions.push({ condition: "" });
    },
  },

  computed: {
    associated_companies() {
      if (!this.user_data) return [];

      const associated_lender_companies = [];

      associated_lender_companies.push(
        ...this.user_data.companies.filter((c) => c.lender)
      );

      associated_lender_companies.push(
        ...this.user_data.auth_companies.filter((c) => c.lender)
      );
      return associated_lender_companies;
    },

    accounts_options() {
      return this.associated_companies.flatMap((c) => {
        if (this.new_offer.selected_company == c.id) {
          return c.accounts.map((account) => {
            return {
              id: account.id,
              name: account.name ? account.name : "Cash",
            };
          });
        } else {
          return [];
        }
      });
    },
  },

  watch: {
    "new_offer.selected_company": function (newVal, oldVal) {
      this.handle_change_company_selection();
    },
  },

  mounted() {},

  filters: {
    ...filters,
  },
};
</script>

<style>
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}
</style>
